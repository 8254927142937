cs.ns("app.ui.composite.abstract.modal");
app.ui.composite.abstract.modal.view = cs.clazz({
    extend:   app.fw.abstract.view,
    dynamics: {
        modal:          null,
        buttonCancel:   null,
        buttonAccept:   null,
        buttonClose:    null,
        title:          null,
        useDefaultPlug: false,
        plugName:       "modalDialog"
    },
    protos:   {

        render () {
            this.base();

            cs(this).plug({ name: this.plugName, object: this.ui, spool: cs(this).state() });
        },

        show () {
            this.modal.modal(this.modalOptions());
            cs(this).guard("release", 0); // remove guard
            cs(this).guard("release", 1); // set new guard
        },

        hide () {
            this.modal.modal('hide');
        },

        prepareMaskReferences () {
            this.base();
            this.modal        = $(".appModalDialog", this.ui);
            this.buttonCancel = $(".appButtonCancel", this.ui);
            this.buttonAccept = $(".appButtonAccept", this.ui);
            this.buttonClose  = $(".appButtonClose", this.ui);
            this.title        = $(".appModalDialogTitle", this.ui);
        },

        prepareMaskRendering () {
            this.title.text(cs(this).value("param:title"));
        },

        registerEventBindings () {
            this.modal.on('shown.bs.modal', () => {
                cs(this).value("event:modalIsShown", true)
            });

            this.modal.on('hidden.bs.modal', () => {
                cs(this).guard("release", 0)
            });

            const clickEventBindings = [
                { event: "event:cancelModal", uiElement: this.buttonCancel },
                { event: "event:acceptModal", uiElement: this.buttonAccept },
                { event: "event:cancelModal", uiElement: this.buttonClose }
            ];

            _.forEach(clickEventBindings, (binding) => {
                binding.uiElement.click(() => {
                    cs(this).value(binding.event, true)
                });
            });
        },

        focus () {
            //muss überschrieben werden, wenn benötigt
        },

        modalOptions () {
            return {
                keyboard: false,
                show:     true,
                backdrop: 'static'
            }
        }
    }
});